.variants-container {
  margin-top: 64px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 32px;
}

.row {
  width: 100%;
  display: flex;
  gap: 16px;
  justify-content: center;
}

.inner-row {
  display: flex;
  gap: 32px;
}

.card {
  padding: 32px;
  width: 448px;
  height: 512px;
}

.calendar-text {
  margin: auto;
}

.calendar-text-example {
  text-align: center;

  & > div {
    margin-bottom: 32px;

    & > p {
      font-family: monospace;
    }
  }

  img {
    max-width: 180px;
  }
}

.text-container {
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

@media screen and (max-width: 1000px) {
  .text-container {
    width: 90%;

    & > div {
      justify-content: start;

      & > div {
        width: 100%!important;
      }
    }
  }
}

@media screen and (max-width: 979px) {
  .variants-container {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 767px) {
  .row {
    width: 90%;
    justify-content: start;
  }

  .inner-row {
    flex-direction: column;
  }
}

@media screen and (max-width: 587px) {
  .subtitle {
    display: none;
  }

  .card {
    width: 100%;
  }

  .variants-container {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 90%;
  }
}

@media screen and (max-width: 477px) {
  .card:nth-of-type(3) {
    pre {
      font-size: 14px!important;
    }
  }
}

@media screen and (max-width: 444px) {
  .title {
    font-size: 46px;
  }
}

@media screen and (max-width: 358px) {
  .card:nth-of-type(3) {
    pre {
      font-size: 12px!important;
    }
  }

  .card {
    padding: 32px 0;

    & > div {
      padding: 0 32px;
    }
  }

  .calendar-text-example {
    font-size: 13px;
  }
}

.mono {
  font-family:
  "SFMono-Regular",
  Consolas,
  "Liberation Mono",
  Menlo,
  Courier,
  monospace!important;

  pre {
    font-family:
    "SFMono-Regular",
    Consolas,
    "Liberation Mono",
    Menlo,
    Courier,
    monospace!important;
  }
}
